import React from "react";
import { Svgs } from "../../assets/images";
import {
  AboutJob,
  AboutJobTitle,
  ApplyButton,
  CityTitle,
  JobTitle,
  PageWrapper,
  // Paragraph,
  // SubTitle,
  Title,
} from "./styles";

const CareersDetail = ({ history }) => {
  const handleSend = () => {
    window.open('mailto: team@sabertoothmotorclub.com', '_self');
  }
  return (
    <PageWrapper>
      <Title>CAREERS</Title>
      <JobTitle>Project Manager</JobTitle>
      <CityTitle>Chicago</CityTitle>
      <AboutJobTitle>About SPC</AboutJobTitle>
      <AboutJob>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
        purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor
        rhoncus dolor purus non enim praesent elementum facilisis leo, vel
        fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis
        enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra
        orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae
        tortor condimentum lacinia quis vel eros donec ac odio tempor orci
        dapibus ultrices in iaculis nunc sed augue lacus, viverra vitae congue
        eu, consequat ac felis donec et odio pellentesque diam volutpat commodo
        sed egestas egestas fringilla phasellus
      </AboutJob>
      <AboutJobTitle>Responsibilities</AboutJobTitle>
      <AboutJob>
        • Create project/sprint plan basis bandwidth of team members involved
      </AboutJob>
      <AboutJob>• Create task lists, split up into sprints</AboutJob>
      <AboutJob>
        • Collate all files and data for the project, again divided as sprints
      </AboutJob>
      <AboutJob>• Collate feedback/create an MoM</AboutJob>
      <AboutJob>
        • Coordinate tasks for the team and make working more efficient and
        effective
      </AboutJob>
      <AboutJob>
        • Take charge of kick-off of a project from sending introductory emails
        to creation of proposal and plan
      </AboutJob>
      <AboutJob>
        • Love for making and sustaining relationships for creating new business
        opportunities
      </AboutJob>
      <AboutJob>
        • Strong interpersonal skills – being comfortable working with CXOs,
        Directors, designers, alike
      </AboutJob>

      <ApplyButton onClick={handleSend}>
        Apply Now <Svgs.ARROW_WHITE width={24} />
      </ApplyButton>
    </PageWrapper>
  );
};

export default CareersDetail;
