import React from "react";
import { Svgs } from "../../assets/images";
import {
  DiscordButton,
  JoinText,
  PageWrapper,
  SMCDescription,
  SMCText,
} from "./styles";

const Join = (id) => {
  const handleNav = () => {
    window.open("https://discord.gg/sabertoothpokerclub", "_blank");
  };
  return (
    <PageWrapper>
      <JoinText id={id}>Get on the</JoinText>
      <SMCText>whitelist</SMCText>
      <SMCDescription>
        Join our Discord and whitelist today to reserve your spot on the coolest
        NFT and metaverse project!
      </SMCDescription>
      <DiscordButton onClick={handleNav}>
        Join our Discord <Svgs.DISCORD />
      </DiscordButton>
    </PageWrapper>
  );
};

export default Join;
