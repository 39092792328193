import React from "react";
import { Svgs, Pngs } from "../../assets/images";
import {
  GridContent,
  PageWrapper,
  SectionTitle,
  TeamGrid,
  TeamGridAvatar,
  TeamGridAvatarTitle,
  TeamGridDescription,
  TeamGridName,
  TeamGridSocial,
} from "./styles";

const data = [
  {
    id: 1,
    avatar: Pngs.FOUNDER,
    name: "Ryan R.",
    title: "CEO",
    description:
      "Founder of Sabertooth Poker Club and creative director. Ryan plans the future and mobilizes the team for the present.",
    twitter: "Chronos_eth",
    linkedin: "ryanronquillo",
    instagram: "Chronos.eth",
  },
  {
    id: 2,
    avatar: Pngs.VISHNUGP,
    name: "Vishnu G.",
    title: "engineer",
    description:
      "Backend engineer overseeing the plumbing for our web and mobile platforms. ",
    twitter: "vishnu_g_p",
    linkedin: "",
    instagram: "gp.vishnu",
  },
  {
    id: 3,
    avatar: Pngs.PITAZ,
    name: "Peter O.",
    title: "engineer",
    description:
      "Frontend engineer building everything you see on our web and mobile platforms. ",
    twitter: "_pitaz",
    linkedin: "",
    instagram: "",
  },
  {
    id: 4,
    avatar: Pngs.AJITH,
    name: "Ajith D.",
    title: "UX/UI Designer",
    description:
      "UX/UI designer making sure our web and mobile designs and branding are the best in the game. ",
    twitter: "sevenate9ine",
    linkedin: "",
    instagram: "design_monkeyy",
  },
  {
    id: 5,
    avatar: Pngs.ALIVIA,
    name: "Alivia S.",
    title: "3d Artist",
    description:
      "3D artist with a hand in all things design and animation. She makes your avatar dance.",
    twitter: "kingxikea",
    linkedin: "",
    instagram: "_kingikea",
  },
  // {
  //   id: 6,
  //   avatar: Pngs.CHRISTOPHER,
  //   name: "Christopher S.",
  //   title: "3d Artist",
  //   description:
  //     "3D artist making sure your metaverse avatar looks fresh to death.",
  //   twitter: "",
  //   linkedin: "",
  //   instagram: "chris_serrano_",
  // },
  // {
  //   id: 7,
  //   avatar: Pngs.JUSTIN,
  //   name: "Justin F.",
  //   title: "3d Artist",
  //   description:
  //     "3D artist and Discord community manager. He keeps your avatars fly and the community booming. ",
  //   twitter: "finkgraphics",
  //   linkedin: "",
  //   instagram: "Finktheartist",
  // },
  {
    id: 6,
    avatar: Pngs.NOAH,
    name: "Noah A.",
    title: "3d Artist",
    description:
      "3D artist who makes the Sabertooth the best you've ever seen and keeps them dancing with animated videos. ",
    twitter: "yinkaart",
    linkedin: "",
    instagram: "yinkaart",
  },
  {
    id: 7,
    avatar: Pngs.MOH,
    name: "Mohamed F.",
    title: "Engineer",
    description:
      "Unity Engineer working with the SPC team to deliver the best 3D poker metaverse experience you could imagin",
    twitter: "MohamedFakhry_",
    linkedin: "",
    instagram: "",
  },
  
  // {
  //   id: 8,
  //   avatar: Pngs.MOH,
  //   name: "Mohamed F.",
  //   title: "Engineer",
  //   description:
  //     "Unity Engineer working with the SPC team to deliver the best 3D poker metaverse experience you could imagin",
  //   twitter: "MohamedFakhry_",
  //   linkedin: "",
  //   instagram: "",
  // },
  {
    id: 8,
    avatar: Pngs.SANTO,
    name: "Santo S.",
    title: "Engineer",
    description:
      "Engineer with the best smart contracts and leading all blockchain management for our decentralized metaverse.",
    twitter: "",
    linkedin: "",
    instagram: "santo.sunil.dev",
  },
];

const MeetTheTeam = (id) => {
  return (
    <PageWrapper className="team">
      <SectionTitle id={id}>Meet the SPC Team</SectionTitle>
      <TeamGrid>
        {data.map((i, idx) => (
          <GridContent key={i.id} className={idx === 0 && 'first-item'}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <TeamGridAvatar src={i.avatar} alt="" />
              <TeamGridAvatarTitle>{i.name}</TeamGridAvatarTitle>
              <TeamGridName>{i.title}</TeamGridName>
              <TeamGridDescription>{i.description}</TeamGridDescription>
            </div>
            <TeamGridSocial>
              {i?.twitter && (
                <a
                  href={`https://www.twitter.com/${i.twitter}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.TWITTER_BLACK className="icon" />
                </a>
              )}
              {i?.linkedin && (
                <a
                  href={`https://www.linkedin.com/in/${i.linkedin}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.LINKEDIN_BLACK className="icon" />
                </a>
              )}

              {i?.instagram && (
                <a
                  href={`https://www.instagram.com/${i.instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.INSTAGRAM_BLACK className="icon" />
                </a>
              )}
            </TeamGridSocial>
          </GridContent>
        ))}
      </TeamGrid>
    </PageWrapper>
  );
};

export default MeetTheTeam;