import styled from "styled-components";
import { Pngs } from "../../assets/images";

export const PageWrapper = styled.div`
  background-image: url(${Pngs.BACKGROUND});
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .welcome {
    font-family: "Cinzel Decorative", cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 31px;
    color: #ffffff;
    margin-top: 200px;
    z-index: 10;
  }
  .smc-club {
    font-family: "Cinzel Decorative", cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 92px;

    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 195px;
    color: #ffffff;
    z-index: 10;
    text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
  }

  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin-top: 16px;
    z-index: 10;
    /* width: 100%; */
  }

  .avatars {
    /* position: absolute; */
    /* height: 537px; */
    width: 100%;
    bottom: 0;
  }
  @media screen and (max-width: 1194px) {
    height: unset;
    .welcome {
      font-family: "Cinzel Decorative", cursive;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-transform: uppercase;
      margin-top: 150px;
      color: #ffffff;
    }

    .smc-club {
      font-family: "Cinzel Decorative", cursive;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-top: 124px;
      color: #ffffff;
      padding: 0 5px;
      text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.55);
    }
    .description {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;

      color: #ffffff;
      padding: 0 20px;
      /* width: 95%; */
    }
    .avatars {
      position: relative;
    }
  }
`;
