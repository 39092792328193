import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 80px 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fcfcfc;
  .aug {
      margin-left: -35px;
    }
  @media screen and (max-width: 1197px) {
    padding: 48px 17px;
    .aug {
      margin-left: -48px;
    }
  }
`;

export const SectionTitle = styled.p`
  font-family: "Cinzel Decorative", cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 61px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #1e1e1e;
  @media screen and (max-width: 1197px) {
    font-family: "Cinzel Decorative", cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.02em;
  }
`;

export const SectionDescription = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  text-align: center;

  color: rgba(30, 30, 30, 0.8);
  margin-top: 16px;
  width: 40%;
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    width: 80%;
    color: rgba(30, 30, 30, 0.8);
  }
`;

export const TeamGrid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 82px;
  margin-top: 64px;
  width: 100%;
  @media screen and (max-width: 1197px) {
    /* grid-template-columns: repeat(1, 1fr); */
    gap: 80px;
    margin-top: 64px;
  }
`;

export const GridContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  box-shadow: 0px 4px 23px rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  padding: 48px 36px 48px;
  position: relative;
  /* max-width: 350px; */
`;

export const GridTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #1e1e1e;
  /* text-align: center; */
  margin-top: 56px;
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-transform: uppercase;

    color: #1e1e1e;
  }
`;

export const Description = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  /* text-align: center; */

  color: rgba(101, 101, 101, 0.8);
  padding-top: 16px;
  width: 100%;
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    /* or 186% */

    color: rgba(101, 101, 101, 0.8);
  }
`;

export const IconBackground = styled.div`
  background: #171717;
  border: 1px solid #686868;
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(255, 255, 255, 0.06);
  border-radius: 10px;
  padding: 25px;
  position: absolute;
  top: -50px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .card-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: rgba(30, 30, 30, 0.8);
    text-transform: capitalize;
  }

  .sub-title {
    font-family: Cinzel Decorative;
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 35px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #1e1e1e;
    margin-top: 10px;
  }

  @media screen and (max-width: 1197px) {
    .card-title {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      /* identical to box height */

      letter-spacing: 0.02em;

      color: rgba(30, 30, 30, 0.8);
    }
    .sub-title {
      font-family: Cinzel Decorative;
      font-style: normal;
      font-weight: 900;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: 0.02em;

      color: #1e1e1e;
    }
  }
`;

export const MonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 360px;
  width: 385px;
  .title-row {
    /* z-index: 1px; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    position: relative;
  }

  .left-label {
    margin-left: 50px;
  }

  @media screen and (max-width: 1197px) {
    margin-bottom: 30px;
    width: 100%;

    .left-label {
      margin-left: 30px;
    }
  }
`;
export const MonthLabel = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.02em;
  background: linear-gradient(114.44deg, #0038f5 0%, #9f03ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;

  @media screen and (max-width: 1197px) {
    font-size: 22px;
    line-height: 34px;
  }
`;

export const MonthContentWrapper = styled.div`
  background: #eeeeee;
  box-shadow: 0px 4px 23px rgba(255, 255, 255, 0.06);
  border-radius: 12px;
  padding: 32px 20px;
  min-width: 250px;
`;

export const MonthContentDot = styled.div`
  width: 22px;
  height: 22px;
  border: 2.5px solid #b7b7b7;
  background: #ffffff;
  border-radius: 50%;
  margin-right: -75px;
  /* z-index: 100; */
  position: absolute;
  right: 0px;
  @media screen and (max-width: 1197px) {
    margin-left: -35px;
    left: 0;
    z-index: 10;
  }
`;

export const MonthContentDotLeft = styled.div`
  width: 22px;
  height: 22px;
  border: 2.5px solid #b7b7b7;
  background: #ffffff;
  border-radius: 50%;
  margin-left: -75px;
  /* z-index: 100; */
  @media screen and (max-width: 1197px) {
    margin-left: -35px;
    left: 0;
  }
`;
export const MonthContentList = styled.ul`
  padding: 32px 20px;
`;

export const MonthContentListItem = styled.li`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;
  /* or 230% */

  color: #1e1e1e;
  @media screen and (max-width: 1197px) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #1e1e1e;
  }
`;

export const Timeline = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 60px;
  margin-top: 52px;
  position: relative;
  /* width: 100%; */

  @media screen and (max-width: 1197px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 40%;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 40%;
  margin-top: 320px;

  @media screen and (max-width: 1197px) {
    margin-top: 0px;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  /* background-color: #B6B6B6; */
  border-left: 2px dashed #b6b6b6;
  @media screen and (max-width: 1197px) {
    position: absolute;
    left: -22px;
  }
`;

export const MintingText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;

  color: #1e1e1e;

  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    background: linear-gradient(114.44deg, #0038f5 0%, #9f03ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
  }
  @media screen and (max-width: 1197px) {
    font-size: 14px;
    span{
      font-size: 14px;
    }
  }
`;
