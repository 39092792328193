import { useEffect } from "react";
import Footer from "./components/Footer";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const redirect = () => {
    const url = "https://www.sabertoothpokerclub.com/";

    if (window.location.href === url) {
      return;
    }
  
    return window.location.assign(url);
  };

  useEffect(() => {
    redirect();
  }, []);
  

  return (
    <>
      <Routes />
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
