import styled from "styled-components";

export const PageWrapper = styled.div`
  background: #080808;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;

  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    /* margin: 0px 150px; */
    padding-top: 140px;
  }
`;

export const Title = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 72px;
  color: #d81159;

  text-align: center;
  margin-bottom: 10px;
  @media screen and (max-width: 1194px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height */

    color: #d81159;
  }
`;

export const Paragraph = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 32px;
`;

export const SubTitle = styled.p`
  color: #ffffff;
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 120px;
  margin-top: 20px;
  @media screen and (max-width: 1194px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 48px;
    color: #ffffff;
    width: 80%;
    margin-top: 5px;
  }
`;

export const CareerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 131px;
  width: 70%;

  .grid-column {
    padding: 0 0 40px;
    border-bottom: 1px solid #989898;
    margin-bottom: 80px;
  }

  .job-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .job-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
  }

  .arrow {
    cursor: pointer;
  }

  .location {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
    gap: 20px;

    .grid-column {
      padding: 0 0 15px;
    }
    .job-title {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #ffffff;
    }

    .location {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #ffffff;
    }
    .arrow {
      width: 21px;
    }
  }
`;
