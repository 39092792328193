import styled from "styled-components";
import { Svgs } from "../../assets/images";

export const PageWrapper = styled.div`
  background: #000000;
  /* height: 100vh; */
  padding: 100px 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1197px) {
    padding: 100px 20px;
  }
`;

export const PageTitle = styled.p`
  color: #ffffff;
  text-align: center;
  font-size: 48px;
  font-family: poppins;
  @media screen and (max-width: 1197px) {
    font-size: 28px;
  }
`;

export const PageDescription = styled.p`
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  font-family: poppins;
  width: 64%;
  margin-top: 20px;
  @media screen and (max-width: 1197px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const ContentSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* grid-template-columns: 1fr 1fr; */
  gap: 30px;
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1197px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 941px) {
    margin-top: 30px;
    grid-template-columns: 1fr;
  }
  /* background-color: red; */
`;

export const ContentSection2 = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 100px;
  align-items: center;

  /* background-color: red; */
  @media screen and (max-width: 1197px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 100%;
    font-size: 16px;
  }
`;

export const ContentWearableSection = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;
  gap: 50px;
  /* margin-top: 150px; */
  align-items: center;
  @media screen and (max-width: 1197px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
`;
export const NftWearableSection = styled.div`
  display: grid;
  /* grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) ); */
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: 1197px) {
    gap: 10px;
    /* grid-template-columns: 1fr 1fr; */
  }
`;

export const List = styled.ul`
  color: #ffffff;
  font-size: 20px;
  font-family: poppins;
  margin-top: 20px;
  li {
    color: #ffffff;
    line-height: 42px;
  }
  p {
    color: #ffffff;
    line-height: 42px;
  }
  @media screen and (max-width: 1197px) {
    width: 100%;
    font-size: 16px;

    p {
      line-height: 26px;
    }
  }
`;

export const UList = styled.ul`
  color: #ffffff;
  font-size: 20px;
  font-family: poppins;
  margin-top: 20px;
  li {
    color: #ffffff;
    line-height: 42px;
  }
  p {
    color: #ffffff;
    line-height: 42px;
  }
  @media screen and (max-width: 1197px) {
    width: 100%;
    font-size: 16px;

    p {
      line-height: 26px;
    }
  }
`;

export const ContentImage = styled.img`
  width: 100%;
  ${({ size }) => size === "small" && "height: 200px"};
  object-fit: cover;
  object-position: center;
`;
export const ContentImageGrid = styled.img`
  width: 150px;
`;
export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 0 50px;
  /* justify-content: space-between; */
  /* background: red; */
  height: 100%;
  p {
    color: #ffffff;
    font-size: 20px;
    font-family: poppins;
    line-height: 25px;
    margin-bottom: 40px;
  }
  span {
    font-weight: bold;
  }

  @media screen and (max-width: 1197px) {
    padding: 0 0;
    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
`;

export const StackImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const PokerRightImageWrapper = styled.div`
  position: relative;
  width: 50%;

  p {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 30px;
    color: #ffffff;
    font-size: 22px;
  }
  @media screen and (max-width: 1197px) {
    width: 100%;
    p {
      font-size: 16px;
    }
  }
`;

export const PokerLeftImageWrapper = styled.div`
  position: absolute;
  width: 50%;
  top: 280px;
  left: 220px;
  p {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 30px;
    color: #ffffff;
    font-size: 22px;
  }

  @media screen and (max-width: 1197px) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 20px;
    p {
      font-size: 16px;
    }
  }
`;

export const BackButton = styled(Svgs.BACK_ARROW_WHITE)`
  cursor: pointer;
  position: absolute;
  left: 180px;
  @media screen and (max-width: 1197px) {
    left: 20px;
  }
`;

export const DiscordButton = styled.a`
  border: none;
  background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  /* identical to box height */
  text-decoration: none;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: space-between;

  color: #ffffff;
  padding: 13px 30px;
  @media screen and (max-width: 1065px) {
    /* display: flex;
    flex-direction: column; */
    padding: 11px 17px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`;
