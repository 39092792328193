import React from "react";
import { Pngs } from "../../assets/images";
import {
  BackButton,
  ContentImage,
  ContentImageGrid,
  ContentSection,
  ContentSection2,
  ContentWearableSection,
  GridItem,
  List,
  NftWearableSection,
  PageDescription,
  PageTitle,
  PageWrapper,
  PokerLeftImageWrapper,
  PokerRightImageWrapper,
  StackImageWrapper,
  UList,
} from "./styles";

const Poker = ({ history }) => {
  const goBack = () => {
    history.goBack();
  };
  return (
    <PageWrapper>
      <BackButton onClick={goBack} />
     
      <PageTitle>How Is It Different</PageTitle>
      <PageDescription>
        SPC Poker is different because it has the most polished and frictionless
        “rentable marketplace” enabling PASSIVE INCOME for NFT owners, and
        active income for players who cannot afford an NFT. The main goal is for
        players to eventually become owners.
      </PageDescription>

      <ContentSection>
        <UList>
          <li>
            SPC NFTs are the ‘pass’ which allow their holder to participate in
            SPC Poker.
          </li>
          <li>
            SPC NFTs can get pricey, and the demand is higher than the supply.
          </li>
          <li>
            To incentivize more widespread gameplay, and earning, delegation is
            a feature.
          </li>
          <li>
            Delegation = Owners can easily ‘rent out’ their NFTs to anyone with
            a Metamask wallet.
          </li>
          <li>
            Upon delegation, the delegate can now play SPC poker in the
            metaverse, earn $SPC, with zero upfront investment!
          </li>
          <li>
            The NFT owners can also benefit, because they get an instant split
            from their player's earnings at the end of each day:
          </li>
          <li>Rank 1 NFT = 60% to player, 40% to owner</li>
          <li>Rank 2 NFT = 57% to player, 43% to owner</li>
          <li>Rank 3 NFT = 54% to player, 46% to owner</li>
          <li>Rank 4 NFT = 51% to player, 49% to owner</li>
          <li>Rank 5 NFT = 48% to player, 51% to owner</li>
          <li>Rank 6 NFT = 45% to player, 55% to owner</li>
        </UList>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <ContentImage src={Pngs.SABERTOOTH2} alt="" />
          <p style={{ fontSize: 18 }}>Rank 2</p>
        </div>
      </ContentSection>
      <PageTitle style={{ marginTop: 150 }}>How it works</PageTitle>
      <PageDescription>
        SPC Poker does NOT involve gambling. It is a free-play, daily tournament
        style of poker. SPC Poker and $SPC coin will be available in the Chronos Metaverse in
        Q3'22.
      </PageDescription>

      <ContentSection>
        <List>
          <li>
            In order to participate, you either need to own an SPC Poker NFT, or
            have someone who does one delegate it to you.
          </li>
          <li>
            Once you have possession of an SPC Poker NFT, you can now check in.
          </li>
          <li>
            Every 24 hours (7PM EST), the leaderboard resets, and NFT holders
            receive 3,000 free-play chips.
          </li>
          <li>
            You now have the next 24 hours, to play your chips and complete the
            three (3) random, daily challenges.
          </li>
          <li>
            Upon completion of a challenge (i.e. get 3 of a kind three times),
            you earn $SPC! $SPC is the reward coin which has real world value.{" "}
          </li>
          <li>
            At the end of each day, SPC rewards are distributed to all players
            and delegators.
          </li>
        </List>

        <ContentImage src={Pngs.POKER_HOUSE} alt="" />
      </ContentSection>

      <PageTitle style={{ marginTop: 150 }}>
        How to Earn SPC Poker Coins
      </PageTitle>
      <PageDescription>
        $SPC poker coins form the play-to-earn backbone of SPC poker. These
        tokens have real-world value since you can exchange them for any other
        cryptocurrency or even fiat currency. While you get SPC poker chips for
        free when you check into SPC poker, you have to earn $SPC coins. There
        are several ways to earn $SPC.
      </PageDescription>

      <ContentSection>
        <UList>
          <p>
            First, you can complete 3 daily challenges. These challenges change
            from day to day, but they typically involve trying different poker
            games or making certain types of bets. If you complete all 3
            challenges within 24 hours, you’ll receive $SPC tokens
            automatically.
          </p>
          <br />
          <p>
            Second, you can win poker games. Winning a hand earns you SPC poker
            chips, and the players with the most SPC poker chips throughout the
            day make it onto a live leaderboard. The longer you stay at the top
            of the leaderboard, the more $SPC poker token you earn.
          </p>
          <br />
          <p>
            Finally, you can earn $SPC by loaning out your SPC poker NFT
            wearable to another player. This means that you won’t be able to
            play poker, but you’re entitled to a share of any $SPC coin that
            your delegate player earns.
          </p>
          <br />
          <p>
            Of course, you will be able to purchase $SPC at any major crypto
            exchange that trades this coin.
          </p>
        </UList>

        <StackImageWrapper>
          <PokerRightImageWrapper>
            <p>$SPC Coin / Token</p>
            <ContentImage src={Pngs.SMCCOINGOLD} alt="" />
          </PokerRightImageWrapper>
          <PokerLeftImageWrapper>
            <p>SPC Poker Chip</p>
            <ContentImage src={Pngs.SMCPOKERCHIP} alt="" />
          </PokerLeftImageWrapper>
        </StackImageWrapper>
      </ContentSection>

      <PageTitle style={{ marginTop: 150 }}>SPC Poker NFTs</PageTitle>
      <PageDescription>
        SPC poker wearable NFTs are required in order to play SPC poker. There
        are several ways to get an SPC poker NFT
      </PageDescription>

      <ContentSection>
        <UList>
          <p>
            First, you can buy one. You can find wearable NFTs for SPC poker on
            OpenSea and other NFT marketplaces.
          </p>
          <br />
          <p>
            Second, you can borrow an SPC poker NFT. When you borrow an NFT, you
            can play poker and the individual from whom you borrowed the
            wearable is entitled to a share of any $SPC coins you earn.
          </p>
          <br />
          <p>
            There is currently no centralized marketplace for finding SPC NFTs
            to borrow for SPC Poker. So, you’ll need to connect with current NFT
            owners in Chronos or on Discord.
          </p>
          <br />
          <p>Finally, you can mint a new SPC NFT when they are released.</p>
          <br />
          <p>
            NFT owners with a higher ranking NFT will receive a larger % split
            from their player’s earnings at the end of each day. Rank 6 (land
            parcels) are the highest ranked SPC NFTs
          </p>
        </UList>

        <ContentWearableSection>
          <GridItem>
            <p>Rank 1 = Wearable NFTs</p>
            <ContentImageGrid src={Pngs.CASAHOODIE} alt="" />
          </GridItem>
          <GridItem>
            <p>Rank 2 = Sabertooth NFTs</p>
            <ContentImageGrid src={Pngs.SABERTOOTH} alt="" />
          </GridItem>
          <GridItem>
            <p>Rank 3 = Car NFTs</p>
            <ContentImageGrid src={Pngs.CAR} alt="" />
          </GridItem>
          <GridItem>
            <p>Rank 4 = Home/Condo NFTs</p>
            <ContentImageGrid src={Pngs.CONDOUNIT} alt="" />
          </GridItem>
          <GridItem>
            <p>Rank 5 = Dragon NFTs</p>
            <ContentImageGrid src={Pngs.DRAGONS} alt="" />
          </GridItem>
          <GridItem>
            <p>Rank 6 = Land parcel NFTs</p>
            <ContentImageGrid src={Pngs.LAND} alt="" />
          </GridItem>
        </ContentWearableSection>
      </ContentSection>

      <PageTitle style={{ marginTop: 150 }}>
        How to Play SPC Poker in Chronos
      </PageTitle>
      <PageDescription>
        If you want to play SPC poker in Chronos, follow these quick steps.
      </PageDescription>

      <ContentSection2>
        <GridItem>
          <p>
            <span>Step 1: Connect a Metamask wallet.</span> Set up a Metamask
            wallet and connect it to Chronos Games.
          </p>
          <ContentImage src={Pngs.METAMASK_IMAGE} alt="" />
        </GridItem>
        <GridItem>
          <p>
            <span>Step 2: Purchase or borrow an SPC wearable. </span> Buy an SPC
            wearable NFT from a marketplace like OpenSea or borrow a wearable
            from a current player
          </p>
          <NftWearableSection>
            <ContentImageGrid
              style={{ width: 120 }}
              src={Pngs.SABERTOOTH}
              alt=""
            />
            <ContentImageGrid style={{ width: 120 }} src={Pngs.CAR} alt="" />
            <ContentImageGrid
              style={{ width: 120 }}
              src={Pngs.DRAGONS}
              alt=""
            />
          </NftWearableSection>
        </GridItem>
        <GridItem>
          <p>
            <span>Step 3: Visit the SPC Poker Stronghold. </span>
            Enter Chronos & navigate to the SPC Poker Stronghold.
          </p>
          <ContentImage src={Pngs.CHRONOS_CARS} alt="" />
        </GridItem>
        <GridItem>
          <p>
            <span>Step 4: Start Playing. </span>
            Join a poker table and select your buy-in to begin playing
          </p>
          <ContentImage src={Pngs.POKER_HOUSE} alt="" />
        </GridItem>
      </ContentSection2>
    </PageWrapper>
  );
};

export default Poker;
