import FOUNDER from './pngs/founder.jpg';
import VISHNUGP from './pngs/vishnu.png';
import PITAZ from './pngs/pitaz.png';
import ABHINAV from './pngs/abhinav.png';
import ALIVIA from './pngs/alivia.png';
import CHRISTOPHER from './pngs/christopher.png';
import JUSTIN from './pngs/justin.png';
import NOAH from './pngs/noah.png';
import PABLO from './pngs/pablo.png';
import AGUSTIN from './pngs/agustin.png';
import JUMBO_PIC from './pngs/header.png';
// import JUMBO_PIC from './pngs/header_poker4.png';
import MD_JUMBO_PIC from './pngs/mdjumbopic.png';
import SECOND_FOLD from './pngs/secondfold.png';
import MD_SECOND_FOLD from './pngs/mdsecondfold.png';
import BACKGROUND from './pngs/header_poker_background.png';
// import BACKGROUND from './pngs/background.jpg';
import POKER_HOUSE from './pngs/poker-house.png';
import POKER_IMAGE from './pngs/poker-image.png';
import CASAHOODIE from './pngs/casahoodiev2.png';
import CONDOUNIT from './pngs/condounit2.png';
import DRAGONS from './pngs/dragons.png';
import LAND from './pngs/land2.png';
import CAR from './pngs/racecarv4.png';
import SABERTOOTH from './pngs/sabertooth.png';
import METAMASK_IMAGE from './pngs/metamask.png';
import CHRONOS_CARS from './pngs/chronos-cars.png';
import SABERTOOTH2 from './pngs/sabertooth2.png';
import SMCCOINGOLD from './pngs/smccoin-gold.png';
import SMCPOKERCHIP from './pngs/smcpokerchip.png';
import COUNTDOWN from './pngs/countdown.png';
import MOH from './pngs/moh.png';
import AJITH from './pngs/ajith.png';
import SANTO from './pngs/santo.png';



import {ReactComponent as INSTAGRAM} from './svgs/instagram.svg';
import {ReactComponent as TWITTER} from './svgs/twitter.svg';
import {ReactComponent as DISCORD} from './svgs/discord.svg';
import {ReactComponent as LOGO} from './svgs/smc-logo.svg';
import {ReactComponent as FORWARD_ARROW} from './svgs/forward-arrow.svg';
import {ReactComponent as ROCKET} from './svgs/rocket.svg';
import {ReactComponent as MARKETPLACE} from './svgs/marketplace.svg';
import {ReactComponent as MERCHANDISE} from './svgs/merchandise.svg';
import {ReactComponent as GROUP} from './svgs/group.svg';
import {ReactComponent as SPECIE} from './svgs/specie.svg';
import {ReactComponent as MEETUP} from './svgs/meetup.svg';
import {ReactComponent as CONCERTS} from './svgs/concerts.svg';
import {ReactComponent as GREEN_GLOW} from './svgs/green-glow.svg';
import {ReactComponent as ADD} from './svgs/add.svg';
import {ReactComponent as MINUS} from './svgs/minus.svg';
import {ReactComponent as MENU} from './svgs/menu.svg';
import {ReactComponent as CLOSE} from './svgs/close.svg';
import {ReactComponent as PERCENT_NOW} from './svgs/percentnow.svg';
import {ReactComponent as PERCENT_10} from './svgs/percent10.svg';
import {ReactComponent as PERCENT_20} from './svgs/percent20.svg';
import {ReactComponent as PERCENT_40} from './svgs/percent40.svg';
import {ReactComponent as PERCENT_50} from './svgs/percent50.svg';
import {ReactComponent as PERCENT_60} from './svgs/percent60.svg';
import {ReactComponent as PERCENT_70} from './svgs/percent70.svg';
import {ReactComponent as PERCENT_80} from './svgs/percent80.svg';
import {ReactComponent as PERCENT_90} from './svgs/percent90.svg';
import {ReactComponent as PERCENT_100} from './svgs/percent100.svg';
import {ReactComponent as ARROW_WHITE} from './svgs/arrow-white.svg';
import {ReactComponent as TWITTER_BLACK} from './svgs/twitter-black.svg';
import {ReactComponent as LINKEDIN_BLACK} from './svgs/linkedin-black.svg';
import {ReactComponent as CLOSE_WHITE} from './svgs/close-white.svg';
import {ReactComponent as CONDO} from './svgs/condo.svg';
import {ReactComponent as DANCE} from './svgs/dance.svg';
import {ReactComponent as GLOBE} from './svgs/globe.svg';
import {ReactComponent as MUSIC} from './svgs/music.svg';
import {ReactComponent as PAINT} from './svgs/paint-n.svg';
import {ReactComponent as FILE} from './svgs/file.svg';
import {ReactComponent as INSTAGRAM_BLACK} from './svgs/instagram-black.svg';
import {ReactComponent as METAMASK} from './svgs/metamask.svg';
import {ReactComponent as ETHEREUM} from './svgs/ethereum.svg';
import {ReactComponent as SMCLOGO} from './svgs/smc-logo-1.svg';
import {ReactComponent as BACKBUTTON} from './svgs/back-button.svg';
import {ReactComponent as FORWARD} from './svgs/forward.svg';
import {ReactComponent as LIVE_FOREVER} from './svgs/live-forever.svg';
import {ReactComponent as METAVERSE_WHITE} from './svgs/metaverse-white.svg';
import {ReactComponent as NEW_ERA} from './svgs/new-era.svg';
import {ReactComponent as METAMASK_WHITE} from './svgs/metamask-white.svg';
import {ReactComponent as METAVERSE_GRD} from './svgs/metaverse-grd.svg';
import {ReactComponent as FIRE_GRD} from './svgs/fire-grd.svg';
import {ReactComponent as LIVE_GRD} from './svgs/live-grd.svg';
import {ReactComponent as MUTE} from './svgs/mute.svg';
import {ReactComponent as UNMUTE} from './svgs/unmute.svg';
import {ReactComponent as MUTE_WHITE} from './svgs/mute-white.svg';
import {ReactComponent as UNMUTE_WHITE} from './svgs/unmute-white.svg';
import {ReactComponent as RED_CHECK} from './svgs/red-check.svg';
import {ReactComponent as GREY_LINE} from './svgs/grey-line.svg';
import {ReactComponent as DOT} from './svgs/dot.svg';
import {ReactComponent as PURPLE_DOT} from './svgs/purple-dot.svg';
import {ReactComponent as BACK_ARROW_WHITE} from './svgs/back-arrow-white.svg';


export const Svgs = {
  INSTAGRAM,
  TWITTER,
  DISCORD,
  LOGO,
  FORWARD_ARROW,
  ROCKET,
  MARKETPLACE,
  MERCHANDISE,
  GROUP,
  SPECIE,
  MEETUP,
  CONCERTS,
  GREEN_GLOW,
  MINUS,
  ADD,
  MENU,
  CLOSE,
  PERCENT_NOW,
  PERCENT_10,
  PERCENT_20,
  PERCENT_40,
  PERCENT_50,
  PERCENT_60,
  PERCENT_70,
  PERCENT_80,
  PERCENT_90,
  PERCENT_100,
  ARROW_WHITE,
  TWITTER_BLACK,
  LINKEDIN_BLACK,
  CLOSE_WHITE,
  INSTAGRAM_BLACK,
  CONDO,
  DANCE,
  FILE,
  GLOBE,
  MUSIC, 
  PAINT,
  METAMASK,
  SMCLOGO,
  ETHEREUM,
  BACKBUTTON,
  FORWARD,
  NEW_ERA,
  LIVE_FOREVER,
  METAVERSE_WHITE,
  METAMASK_WHITE,
  METAVERSE_GRD,
  FIRE_GRD,
  LIVE_GRD,
  MUTE,
  UNMUTE,
  MUTE_WHITE,
  UNMUTE_WHITE,
  RED_CHECK,
  GREY_LINE,
  DOT,
  PURPLE_DOT,
  BACK_ARROW_WHITE
}

export const Pngs = {
  FOUNDER,
  VISHNUGP,
  PITAZ,
  ABHINAV,
  ALIVIA,
  CHRISTOPHER,
  JUSTIN,
  NOAH,
  PABLO,
  JUMBO_PIC,
  MD_JUMBO_PIC,
  SECOND_FOLD,
  MD_SECOND_FOLD,
  AGUSTIN,
  BACKGROUND,
  POKER_HOUSE,
  CASAHOODIE,
  CONDOUNIT,
  LAND,
  SABERTOOTH,
  CAR,
  DRAGONS,
  METAMASK_IMAGE,
  CHRONOS_CARS,
  SABERTOOTH2,
  SMCCOINGOLD,
  SMCPOKERCHIP,
  POKER_IMAGE,
  COUNTDOWN,
  MOH,
  AJITH,
  SANTO
}
