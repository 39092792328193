import React, { useState } from "react";
import { Svgs } from "../../assets/images";
import { PageWrapper, TitleWhite, FAQrow } from "./styles";

const data = [
  {
    id: 1,
    faq: "What is Sabertooth Poker Club?",
    faqContent: `SPC is a collection of 3,333 Sabertooth Tiger NFTs - unique digital
      collectibles stored on the Ethereum blockchain as ERC-721 tokens. Your
      Sabertooth NFT doubles as your Poker Club membership card, and grants
      access to members-only benefits. \n  Each Sabertooth Tiger is unique and generated from hundreds of
      characteristics. All Sabertooths are dope, and some are more rare than
      others.`,
  },
  {
    id: 2,
    faq: "What is the total supply?",
    faqContent: `There are 3,333 NFT's available for minting (200 which will be reserved for our team, influencers, and giveaways).`,
  },
  // {
  //   id: 3,
  //   faq: "When is the launch?",
  //   faqContent: `The Whitelist presale will be on April 15th, and the public sale will be April 16th.
  //   `,
  // },
  // {
  //   id: 4,
  //   faq: "When will my Sabertooth Tiger NFT be revealed?",
  //   faqContent:
  //     "When revealed? The Sabertooth NFT will be revealed 24 hours after the mint date.",
  // },
  // {
  //   id: 5,
  //   faq: "What will be the mint price?",
  //   faqContent: `The Whitelist presale mint price will be 0.07 ETH, and the the public sale mint price will be 0.09 ETH.`,
  // },
  {
    id: 3,
    faq: "What is Metamask?",
    faqContent: `Metamask is a crypto wallet that can store your Ethereum, and is needed to purchase and mint a Sabertooth NFT. Having a wallet gives you an Ethereum address (i.e. 0xABCD….1234), this is where your NFT will be stored. `,
  },
  {
    id: 4,
    faq: "How do I mint on mobile?",
    faqContent: `Download metamask from iOS app store or Android playstore. Copy and paste the minting link provided at the time of mint in the internal metamask browser.`,
  },
  {
    id: 5,
    faq: "How will you use funds from the mint and royalties?",
    faqContent: `We will use the funds to expand our team (3D artists and engineers) and 
      metaverse world. We will continue to build additional NFT collections, exclusive 
      virtual 3D experiences, new events and rewards for our community, increase 
      awareness of our project through marketing, and take the project to the moon`,
  },
  {
    id: 6,
    faq: "Why do we need royalties?",
    faqContent:
      "The amount of royalties was fixed at 5% to finance the Sabertooth Poker Club projects and expansion. The funds collected will benefit the holders through how they are spent to increase the popularity and strength of the project. Also, exclusive events for the holders will be organized in top cities of the world. This percentage can decrease over time.",
  },
  {
    id: 7,
    faq: "What are Giveaways?",
    faqContent: `We have reserved up to 200 Sabertooth NFTs to giveaway to early adopters, 
      supporters, and members that help build the community. The creator team will 
      each receive giveaways as well. Most of these will be given out after launch and 
      will not occupy the early token ids. The remainder of the Sabertooth NFTs' are all 
      for sale.`,
  },
];

const FAQs = (id) => {
  const [selected, setSelected] = useState([]);

  const handleClick = (index) => {
    if (selected.includes(index)) {
      const filter = selected.filter((i) => i !== index);
      return setSelected(filter);
    }
    return setSelected((prev) => [...prev, index]);
  };
  return (
    <PageWrapper className="faq">
      <TitleWhite id={id}>Frequently asked questions</TitleWhite>
      {data.map((item, index) => (
        <FAQrow key={index}>
          <div>
            <p className="faq-text" onClick={() => handleClick(index)}>
              {item.faq}
            </p>
            {selected.includes(index) && (
              <p className="faq-content">{item.faqContent}</p>
            )}
          </div>
          {selected.includes(index) ? (
            <Svgs.CLOSE_WHITE
              style={{
                cursor: "pointer",
                padding: 10,
              }}
              className="icon"
              width={16}
              height={16}
              onClick={() => handleClick(index)}
            />
          ) : (
            <Svgs.ADD
              style={{
                cursor: "pointer",
                padding: 10,
              }}
              className="icon"
              width={16}
              height={16}
              onClick={() => handleClick(index)}
            />
          )}
        </FAQrow>
      ))}
    </PageWrapper>
  );
};

export default FAQs;
