import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 80px 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fcfcfc;
  @media screen and (max-width: 1197px) {
    padding: 48px 17px;
  }
`;

export const SectionTitle = styled.p`
  font-family: "Cinzel Decorative", cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 61px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #1E1E1E;
  /* background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  @media screen and (max-width: 1197px) {
    font-family: "Cinzel Decorative", cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.02em;
  }
`;

export const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 82px;
  margin-top: 64px;
  .first-item {
      /* grid-column-start: 1;
      grid-column-end: 4; */
      
      /* grid-row-start: 1;
      grid-row-end: 3; */

      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  @media screen and (max-width: 1197px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
     .first-item {
    
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    } 
  }
`;

export const TeamGridAvatar = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: #c4c4c4;
  /* padding-top: 20px; */
  @media screen and (max-width: 1197px) {
    width: 146px;
    height: 146px;
    border-radius: 50%;
  }
`;

export const TeamGridAvatarContainer = styled.div`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: #c4c4c4;
  @media screen and (max-width: 1197px) {
    width: 146px;
    height: 146px;
    border-radius: 50%;
  }
`;

export const GridContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TeamGridAvatarTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #1e1e1e;
  margin-top: 12px;
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1e1e1e;
  }
`;

export const TeamGridName = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #1e1e1e;
  margin-top: 6px;
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #1e1e1e;
  }
`;

export const TeamGridDescription = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(30, 30, 30, 0.8);
  margin-top: 32px;
  text-align: center;
  width: 70%;
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(30, 30, 30, 0.8);
  }
`;

export const TeamGridSocial = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: center;
  gap: 14px;

  @media screen and (max-width: 1197px) {
    margin-bottom: 48px;
  }
`;
