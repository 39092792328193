import React, { forwardRef, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { PageWrapper, VideoWrapper } from "./styles";

import "swiper/swiper-bundle.css";
import { Svgs } from "../../assets/images";

const Chronos = forwardRef(({ ref }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const containerRef = useRef();

  const toggleSound = () => setIsMute(!isMute);
  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    const refCurrent = containerRef.current;
    const observer = new IntersectionObserver(callbackFunction, options);
    if (refCurrent) observer.observe(refCurrent);

    return () => {
      if (refCurrent) observer.unobserve(refCurrent);
    };
  }, [containerRef]);

  return (
    <PageWrapper className="about" ref={ref}>
      <VideoWrapper ref={containerRef}>
        <ReactPlayer
          url={"https://d3a8mony9km4bv.cloudfront.net/chronos_v3.mp4"}
          width="100%"
          height="auto"
          muted={isMute}
          controls={false}
          playing={isVisible}
          loop
        />
        <div className="mute-wrapper">
          {isMute ? (
            <Svgs.MUTE_WHITE onClick={toggleSound} className="mute" />
          ) : (
            <Svgs.UNMUTE_WHITE onClick={toggleSound} className="mute" />
          )}

          <p className="mute-text">Tap to {isMute ? "unmute" : "mute"}</p>
        </div>
        <div className="section-text-wrapper ">
          <p className="text-1">WELCOME TO</p>
          <p className="text-2">CHRONOS</p>
          <p className="text-3">
          A free POKER Metaverse where you Play-to-Earn active and PASSIVE INCOME
          </p>
        </div>
      </VideoWrapper>
    </PageWrapper>
  );
});

export default Chronos;
