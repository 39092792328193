import React from "react";
import Countdown from 'react-countdown';
import {
  DiscordButton,
  PageTitle,
  PageWrapper,
  TimeGrid,
  TimeText,
  TimeTextLabel,
  TimeTextWrapper,
} from "./styles";

const CountDown = () => {
  const handleNav = () => {
    window.open("https://discord.gg/sabertoothpokerclub", "_blank");
  };

  const handleNavToMint = () => {
    window.open("https://opensea.io/collection/sabertoothpokerclub", "_blank");
  };

  const Completionist = () =>  <>
  <PageTitle>Buy a Sabertooth NFT & Membership to our P2E Metaverse</PageTitle>
  <DiscordButton onClick={handleNavToMint}>OpenSea</DiscordButton>
  </>

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <>
          <PageTitle>COUNTDOWN TO MINT</PageTitle>
          <TimeGrid>
            <TimeTextWrapper>
              <TimeText>{days}</TimeText>
              <TimeTextLabel>Days</TimeTextLabel>
            </TimeTextWrapper>
            <TimeTextWrapper>
              <TimeText>{hours}</TimeText>
              <TimeTextLabel>Hours</TimeTextLabel>
            </TimeTextWrapper>
            <TimeTextWrapper>
              <TimeText>{minutes}</TimeText>
              <TimeTextLabel>MINS</TimeTextLabel>
            </TimeTextWrapper>
            <TimeTextWrapper>
              <TimeText>{seconds}</TimeText>
              <TimeTextLabel>SECS</TimeTextLabel>
            </TimeTextWrapper>
          </TimeGrid>
          <DiscordButton onClick={handleNav}>Join Whitelist</DiscordButton>
        </>
      );
    }
  };

  return (
    <PageWrapper>
      <Countdown date={new Date('4/15/2022 10:00:00 PM UTC')} renderer={renderer} />
    </PageWrapper>
  );
};

export default CountDown;
