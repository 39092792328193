import React, { useRef } from "react";
import About from "../../components/About";
import Chronos from "../../components/Chronos";
import JumboHeader from "../../components/JumboHeader";
import Nav from "../../components/Nav";
import CountDown from "../Countdown";
import FAQs from "../FAQs";
import Join from "../Join";
import MeetTheTeam from "../MeetTheTeam";
import NewRoadmap from "../NewRoadmap";
// import ProjectRoadMap from "../ProjectRoadMap";
import { Desktop, Mobile, PageWrapper } from "./styles";

const Home = ({ history }) => {
  const aboutRef = useRef();
  const aboutMRef = useRef();
  const roadMapRef = useRef();
  const roadMapMobileRef = useRef();
  const teamRef = useRef();
  const teamMobileRef = useRef();
  const faqRef = useRef();
  const faqMobileRef = useRef();

  return (
    <>
      <Nav
        aboutRef={aboutRef}
        aboutMRef={aboutMRef}
        roadMapRef={roadMapRef}
        roadMapMobileRef={roadMapMobileRef}
        teamMobileRef={teamMobileRef}
        teamRef={teamRef}
        faqRef={faqRef}
        faqMobileRef={faqMobileRef}
      />
      <PageWrapper>
        <Desktop>
          <JumboHeader />
          <div ref={aboutRef} style={{ paddingTop: 80 }}>
            <About />
          </div>
          <CountDown />
          <div ref={roadMapRef}>
            <NewRoadmap />
          </div>
          {/* <NFTBenefit /> */}
          <Chronos />
          {/* <Message id="message" /> */}
          {/* <HowTo /> */}
          <div ref={teamRef} style={{ paddingTop: 80 }}>
            <MeetTheTeam id="team" />
          </div>
          <div ref={faqRef}>
            <FAQs id="faq" />
          </div>
          <Join id="join" />
        </Desktop>
        <Mobile>
          <JumboHeader />
          <div ref={aboutMRef} style={{ paddingTop: 80 }}>
            <About />
          </div>
          <CountDown />
          <div ref={roadMapMobileRef}>
            <NewRoadmap id="roadmap" />
          </div>
          {/* <NFTBenefit /> */}
          <Chronos />
          {/* <Message id="message" /> */}
          {/* <HowTo /> */}
          <div ref={teamMobileRef} style={{ paddingTop: 80 }}>
            <MeetTheTeam id="team" />
          </div>
          <div ref={faqMobileRef} >
          <FAQs id="faq" />
          </div>
          
          <Join id="join" />
        </Mobile>
      </PageWrapper>
    </>
  );
};

export default Home;
