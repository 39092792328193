import styled from "styled-components";

export const PageWrapper = styled.div`
  background: #080808;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;

  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    /* margin: 0px 150px; */
    padding-top: 140px;
  }
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  font-size: 64px;
  line-height: 98px;
  /* or 153% */

  text-align: center;

  color: #ffffff;
  margin-bottom: 40px;
  @media screen and (max-width: 1194px) {
    font-size: 32px;
  }
`;

export const Paragraph = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 32px;
`;

export const SubTitle = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  font-size: 36px;
  line-height: 98px;
  /* or 272% */

  display: flex;
  align-items: center;

  color: #ffffff;
`;
