import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterWrapper = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  */
  background: ${({ isHome }) =>
    isHome
      ? `linear-gradient(to right, #07050F , #07050F, #07050F, #15102D)`
      : "#0e0d0d"};
  ${({ isHome }) =>
    isHome ? "box-shadow: 0px 6px 35px rgba(173, 173, 173, 0.14);" : ""};
  padding-top: 46px;

  @media screen and (max-width: 1194px) {
    position: relative;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px 20px;
  .icon {
    width: 126px;
  }

  .copyright {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 160%;
    /* or 21px */

    text-align: center;
    letter-spacing: 0.04em;

    color: #ffffff;
  }
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    padding: 0 50px 20px;
    align-items: flex-start;
    .icon {
      width: 72px;
    }
  }
`;

export const PageTitle = styled.h2`
  font-family: Montserrat;
  font-style: italic;
  font-weight: bold;
  font-size: 21px;
  line-height: 72px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;

export const IconsWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 32px;
`;

export const FooterText = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  color: #ffffff;
  padding-bottom: 16px;
`;

export const EmailBoxWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 1194px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 286px;
  background: #2e2e2e52;
  border-radius: 8px 0px 0px 8px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  outline: none;
  border: none;
  /* font-feature-settings: "salt" on, "liga" off; */
  color: #ffffff;
  opacity: 1;
  padding: 12px 16px;
  @media screen and (max-width: 1194px) {
    width: 100%;
  }
`;

export const ArrowWrapper = styled.button`
  display: flex;
  background: #424242;
  border-radius: 0px 8px 8px 0px;
  padding: 16px 15px 16px 9px;
  border: none;
  cursor: pointer;
`;

export const ReachOut = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-bottom: 25px;
`;

export const Terms = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #ffffff;
  /* padding-top: 16px; */
  @media screen and (max-width: 1194px) {
    font-size: 16px;
  }
`;

export const Mobile = styled.div`
  display: none;
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Desktop = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1194px) {
    display: none;
  }
`;

export const CopyrightLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`;

export const ReCaptchaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 48px;
  padding: 0 200px 20px;
  .error {
    color: #f94007;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 20px;
    .error {
      font-size: 16px;
    }
  }
`;

export const JoinTeam = styled.div`
  display: flex;
  flex-direction: column;
  visibility: hidden;
  .team-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */

    text-transform: uppercase;

    color: #ffffff;
    
  }
  @media screen and (max-width: 1194px) {
  }
`;

export const FooterStackContent = styled.div`
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
  }
`;
