import styled from "styled-components";

export const NavWrapper = styled.div`
  background: ${({ colorChange }) => (colorChange ? "#000000" : "none")};
  /* box-shadow: 0px 6px 35px rgba(173, 173, 173, 0.14); */
  padding: 20px 150px 15px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  /* transition: ease 5s; */

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .icon {
    width: 76px;
    margin-bottom: -15px;
  }
  .mb-menu-link {
      cursor: pointer;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      line-height: 27px;
      text-align: center;
      text-decoration: none;
      color: #ffffff;
      @media screen and (max-width: 1065px) {
        /* display: none; */
      }
    }
  .menu {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
    gap: 72px;

    a {
      cursor: pointer;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      line-height: 27px;
      text-align: center;
      text-decoration: none;
      color: #ffffff;
      @media screen and (max-width: 1065px) {
        display: none;
      }
    }
  }
  .logo-section {
    display: flex;
    align-items: center;
    gap: 10px;
    visibility: hidden;
    @media screen and (max-width: 1065px) {
      display: none;
    }
  }
  .saber-tooth {
    font-family: "Cinzel Decorative", cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    /* margin-top: -15px; */
    @media screen and (max-width: 1065px) {
      font-size: 18px;
    }
  }
  .mclub {
    font-family: "Cinzel Decorative", cursive;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    /* or 111% */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  @media screen and (max-width: 1389px) {
    /* display: flex;
    justify-content: space-between; */
    padding: 10px 10px 15px;
    font-size: 18px;
    /* padding: 20px; */
    .icon {
      width: 60px;
      padding-left: 19px;
    }
    .menu {
      /* display: none; */
    }
  }
  @media screen and (max-width: 1268px) {
    /* display: flex;
    justify-content: space-between; */
    padding: 10px 10px 15px;
    font-size: 18px;
    /* padding: 20px; */
    .icon {
      width: 60px;
      padding-left: 19px;
    }
    .menu {
      /* display: none; */
    }
  }

  @media screen and (max-width: 1078px) {
    padding: 0px 10px 15px;
    background-color: ${({ colorChange, open }) => (open ? "#000000" : "none")};
    .menu {
      display: none;
    }
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo-section {
      margin: 0 auto;
    }
  }
`;

export const MobileNav = styled.div`
  /* display: none; */
  padding: 20px;
  transition: ease height 4s;
  a {
    cursor: pointer;
    padding-top: 10px;
  }
  @media screen and (max-width: 1065px) {
    /* display: block; */
    transition: ease height 4s;

    /* margin-top: 140px */
  }
`;
export const PageTitle = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  line-height: 27px;
  text-align: center;

  color: #ffffff;
  @media screen and (max-width: 1065px) {
    display: none;
  }
`;

export const MPageTitle = styled.a`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  @media screen and (max-width: 1065px) {
    /* display: none; */
  }
`;

export const IconsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  /* justify-content: space-between; */
  align-items: center;
  gap: 32px;
  @media screen and (max-width: 1069px) {
    display: flex;
    a {
      display: none;
    }
  }
`;

export const MenuIconWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1069px) {
    display: flex;
    cursor: pointer;
  }
`;
export const Mobile = styled.div`
  display: none;
  @media screen and (max-width: 1065px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DiscordButton = styled.button`
  border: none;
  background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  /* identical to box height */
/* width: 20%; */
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: space-between;

  color: #ffffff;
  padding: 13px 30px;
  @media screen and (max-width: 1065px) {
    /* display: flex;
    flex-direction: column; */
    padding: 11px 17px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`;
