import React from "react";
import { useLocation } from "react-router";
// import { toast } from "react-toastify";
import { Svgs } from "../../assets/images";
// import { request } from "../../utils/request";
// import { emailApi } from "../../utils/apiRoutes";
import {
  // ArrowWrapper,
  // EmailBoxWrapper,
  FooterContent,
  // FooterText,
  FooterWrapper,
  IconsWrapper,
  // Input,
  ReachOut,
  Terms,
  Desktop,
  Mobile,
  CopyrightLogoWrapper,
  // ReCaptchaWrapper,
  JoinTeam,
  FooterStackContent,
} from "./styles";
// import Loader from "../Loader";
// import ReCAPTCHA from "react-google-recaptcha";

const Footer = () => {
  // const recaptchaRef = useRef();
  const location = useLocation();
  const isHome = location.pathname === "/";
  // const [email, setEmail] = useState("");
  // const [, setCaptcha] = useState("");
  // const [error, setError] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [, setIsExpired] = useState(false);
  // const [, setResult] = useState();
  // const onChange = (e) => {
  //   setError("");
  //   const { value } = e.target;

  //   return setEmail(value);
  // };

  // useEffect(() => {
  //   if (loading) {
  //     recaptchaRef.current?.execute();
  //   }
  // }, [loading]);

  // const handleSubmit = async () => {
  //   const isValidInput = () => /^\S+@\S+\.\w+$/.test(email);
  //   if (!isValidInput()) return setError("Please enter a valid email");
  //   setLoading(true);
  // };

  const currentYear = new Date().getFullYear();
  // const handleChange = async (value) => {
  //   setCaptcha(value);
  //   if (value === null) return setIsExpired("true");
  //   try {
  //     const res = await request.post(emailApi, {
  //       email,
  //       reCaptchaResponse: value,
  //     });
  //     setLoading(false);
  //     setResult(res);
  //     if (!res?.error) {
  //       return toast.success("Email added to waitlist!");
  //     }
  //     return toast.error("Something went wrong, please try again!");
  //   } catch (err) {
  //     setLoading(false);

  //     toast.error("Something went wrong, please try again!");
  //   }
  // };

  // const footerEmail = () => {
  //   return (
  //     <div>
  //       <FooterText>Get on the list</FooterText>
  //       <EmailBoxWrapper>
  //         <Input
  //           type="email"
  //           placeholder="Email Address"
  //           value={email}
  //           onChange={onChange}
  //         />
  //         <ArrowWrapper onClick={handleSubmit}>
  //           {loading ? <Loader /> : <Svgs.FORWARD_ARROW />}
  //           {/* <Svgs.FORWARD_ARROW /> */}
  //         </ArrowWrapper>
  //       </EmailBoxWrapper>
  //     </div>
  //   );
  // };
  return (
    <FooterWrapper isHome={isHome}>
      <Desktop>
        <FooterContent>
          {/* {footerEmail()} */}
          <JoinTeam>
            <p className="team-text">JOIN OUR TEAM</p>
            <Terms to="/career">Careers</Terms>
          </JoinTeam>
          <CopyrightLogoWrapper>
            <Svgs.LOGO className="icon" />
            <p className="copyright">
              © {currentYear} Sabertooth Motor Club Inc. | All Rights Reserved
            </p>
          </CopyrightLogoWrapper>
          <div>
            <ReachOut>Follow us</ReachOut>
            <IconsWrapper>
              <a
                href="https://www.instagram.com/sabertoothpokerclub"
                target="_blank"
                rel="noreferrer"
              >
                <Svgs.INSTAGRAM />
              </a>
              <a
                href="https://www.twitter.com/sabertoothpoker"
                target="_blank"
                rel="noreferrer"
              >
                <Svgs.TWITTER />
              </a>
              <a
                href="https://discord.gg/sabertoothpokerclub"
                target="_blank"
                rel="noreferrer"
              >
                <Svgs.DISCORD />
              </a>
            </IconsWrapper>
            <div
              style={{
                marginTop: 25,
              }}
            >
              {/* <Terms to="/roadmap">Roadmap</Terms> */}
              <Terms to="/terms">Terms & conditions</Terms>
              <Terms to="/">team@sabertoothmotorclub.com</Terms>
            </div>
          </div>
        </FooterContent>
        {/* {loading && ( */}
        {/* <ReCaptchaWrapper> */}
        {/* <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              theme="light"
              sitekey={process.env.REACT_APP_SITE_KEY}
              // onChange={handleChange}
            /> */}
        {/* <p className="error">{error}</p> */}
        {/* </ReCaptchaWrapper> */}
        {/* )} */}
      </Desktop>

      <Mobile>
        <FooterContent>
          <JoinTeam>
            <p className="team-text">JOIN OUR TEAM</p>
            <Terms to="/career">Careers</Terms>
          </JoinTeam>
          {/* {loading && (
            <ReCaptchaWrapper>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                theme="light"
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={handleChange}
              />
              <p className="error">{error}</p>
            </ReCaptchaWrapper>
          )} */}
          <FooterStackContent>
            <div>
              <ReachOut>Follow us</ReachOut>
              <IconsWrapper>
                <a
                  href="https://www.instagram.com/sabertoothpokerclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.INSTAGRAM />
                </a>
                {/* <Svgs.DISCORD /> */}
                <a
                  href="https://www.twitter.com/sabertoothpoker"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.TWITTER />
                </a>
                <a
                  href="https://discord.gg/sabertoothpokerclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Svgs.DISCORD />
                </a>
              </IconsWrapper>
            </div>
            <div style={{ marginTop: 33 }}>
              {/* <Terms to="/roadmap">Roadmap</Terms> */}
              <Terms to="/terms">Terms & conditions</Terms>
              <Terms to="/">team@sabertoothmotorclub.com</Terms>
              {/* <Terms to="/careers">Careers</Terms> */}
            </div>
          </FooterStackContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Svgs.LOGO className="icon" />
            <p className="copyright">
              Copyright © {currentYear} Sabertooth Motor Club, Inc.
            </p>
          </div>
        </FooterContent>
      </Mobile>
    </FooterWrapper>
  );
};

export default Footer;
