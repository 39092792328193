import styled from "styled-components";

export const PageWrapper = styled.div`
  background: #fcfcfc;
  padding: 140px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1197px) {
    padding: 72px 16px;
  }
`;

export const JoinText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 123% */

  display: flex;
  align-items: center;

  color: #000000;
  @media screen and (max-width: 1197px) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;

    color: #000000;
  }
`;

export const SMCText = styled.p`
  font-family: 'Cinzel Decorative', cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 32px;
  /* identical to box height, or 76% */

  display: flex;
  align-items: center;

  color: #000000;
  margin-top: 20px;
  margin-bottom: 16px;
  @media screen and (max-width: 1197px) {
    font-family: 'Cinzel Decorative', cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    /* or 125% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }
`;

export const SMCDescription = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;

  text-align: center;

  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 48px;
  @media screen and (max-width: 1197px) {
  }
`;

export const DiscordButton = styled.button`
  border: none;
  background: linear-gradient(91.68deg, #8337eb 2.2%, #b84df3 97.23%);
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height */

  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: space-between;

  color: #ffffff;
  padding: 20px 38px;
  cursor: pointer;
  @media screen and (max-width: 1065px) {
    /* display: flex;
    flex-direction: column; */
    padding: 14px 44px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`;
