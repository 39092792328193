import React from "react";
// import { Svgs } from "../../assets/images";
import { Desktop, Mobile } from "../Home/styles";
import {
  Divider,
  LeftContent,
  MintingText,
  MonthContentDot,
  MonthContentDotLeft,
  MonthContentListItem,
  MonthContentWrapper,
  MonthLabel,
  MonthWrapper,
  PageWrapper,
  RightContent,
  SectionDescription,
  SectionTitle,
  Timeline,
} from "./styles";

// const data = [
//   {
//     id: 1,
//     Icon: Svgs.FIRE_GRD,
//     month: "phase 1",
//     content: [
//       {
//         id: 1,
//         title: "SABERTOOTH NFTs",
//       },
//       {
//         id: 2,
//         title: "DANCE IN THE METAVERSE",
//       },
//       {
//         id: 3,
//         title: "Thriving Community",
//       },
//     ],
//   },
//   {
//     id: 2,
//     Icon: Svgs.METAVERSE_GRD,
//     month: "phase 1",
//     content: [
//       {
//         id: 1,
//         title: "SABERTOOTH NFTs",
//       },
//       {
//         id: 2,
//         title: "DANCE IN THE METAVERSE",
//       },
//       {
//         id: 3,
//         title: "Thriving Community",
//       },
//     ],
//   },
//   {
//     id: 3,
//     Icon: Svgs.LIVE_GRD,
//     month: "phase 1",
//     content: [
//       {
//         id: 1,
//         title: "SABERTOOTH NFTs",
//       },
//       {
//         id: 2,
//         title: "DANCE IN THE METAVERSE",
//       },
//       {
//         id: 3,
//         title: "Thriving Community",
//       },
//     ],
//   },
// ];

const HowTo = (id) => {
  return (
    <PageWrapper className="">
      <SectionTitle id={id}>Roadmap</SectionTitle>
      <SectionDescription>
        Here is a glimpse of our roadmap for SPC and our very own metaverse
        world called Chronos.
      </SectionDescription>
      {/* <MintingText>
        Mint date : <span className="date">April 15th</span>{" "}
      </MintingText> */}
      <Timeline>
        <RightContent>
          <MonthWrapper>
            <div className="title-row">
              <MonthLabel>JUNE</MonthLabel>
              <MonthContentDot />
            </div>
            <MonthContentWrapper>
              <MonthContentListItem>
                2nd SPC Metaverse series of poker
              </MonthContentListItem>
              <MonthContentListItem>Private sale of Sabertooth NFTs</MonthContentListItem>
            </MonthContentWrapper>
          </MonthWrapper>
          <Desktop>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel>August</MonthLabel>
                <MonthContentDot />
              </div>
              <MonthContentWrapper>
                <MonthContentListItem>
                  Metaverse launch poker(P2E+ Delegation)
                </MonthContentListItem>
                <MonthContentListItem>
                  Dragon collection mint
                </MonthContentListItem>
                <MonthContentListItem>
                  Car collection mint
                </MonthContentListItem>
              </MonthContentWrapper>
            </MonthWrapper>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel>October</MonthLabel>
                <MonthContentDot />
              </div>
              <MonthContentWrapper>
                <MonthContentListItem>
                 Token launch w/ Staking
                </MonthContentListItem>
                <MonthContentListItem>
                  Race and explore Metaversew/ Cars & Dragons
                </MonthContentListItem>
                <MonthContentListItem>
                  Metaverse Advertising Revenue
                </MonthContentListItem>
              </MonthContentWrapper>
            </MonthWrapper>
          </Desktop>
          
          <Mobile>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel>JULY</MonthLabel>
                <MonthContentDot />
              </div>
              <MonthContentWrapper>
                <MonthContentListItem>
                3rd SPC Metaverse series of poker
                </MonthContentListItem>
                <MonthContentListItem>
                  Wearable collection mint 1
                </MonthContentListItem>
              </MonthContentWrapper>
            </MonthWrapper>
          </Mobile>
        </RightContent>
        <Divider />
        <LeftContent>
          <Desktop>
            <MonthWrapper>
              <div className="title-row">
                <MonthContentDotLeft position="right" />
                <MonthLabel className="left-label">JULY</MonthLabel>
              </div>
              <MonthContentWrapper>
                <MonthContentListItem>
                3rd SPC Metaverse series of poker
                </MonthContentListItem>
                <MonthContentListItem>
                  Wearable collection mint 1
                </MonthContentListItem>
              </MonthContentWrapper>
            </MonthWrapper>
          </Desktop>
          <Mobile>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel>August</MonthLabel>
                <MonthContentDot className="aug" />
              </div>
              <MonthContentWrapper >
              <MonthContentListItem>
                  Metaverse launch poker<br />(P2E+ Delegation)
                </MonthContentListItem>
                <MonthContentListItem>
                  Dragon collection mint
                </MonthContentListItem>
                <MonthContentListItem>
                  Car collection mint
                </MonthContentListItem>
              </MonthContentWrapper>
            </MonthWrapper>
          </Mobile>
          <MonthWrapper style={{ marginBottom: 0 }}>
            <div className="title-row">
              <MonthContentDotLeft position="right" />
              <MonthLabel className="left-label">September</MonthLabel>
            </div>
            <MonthContentWrapper>
              <MonthContentListItem>
                Land Parcel collection mint 1
              </MonthContentListItem>
              <MonthContentListItem>
              Luxury condo collection mint 1
              </MonthContentListItem>
              <MonthContentListItem>
                P2E for all NFT Communities
              </MonthContentListItem>
            </MonthContentWrapper>
          </MonthWrapper>
          <Mobile>
            <MonthWrapper>
              <div className="title-row">
                <MonthLabel style={{marginTop: '20px'}}>October</MonthLabel>
                <MonthContentDot className="aug" />
              </div>
              <MonthContentWrapper>
                <MonthContentListItem>
                 Token launch w/ Staking
                </MonthContentListItem>
                <MonthContentListItem>
                  Race and explore Metaverse <br />w/ Cars & Dragons
                </MonthContentListItem>
                <MonthContentListItem>
                  Metaverse Advertising Revenue
                </MonthContentListItem>
              </MonthContentWrapper>
            </MonthWrapper>
          </Mobile>
        </LeftContent>
      </Timeline>
    </PageWrapper>
  );
};

export default HowTo;
