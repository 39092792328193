import React from "react";
import { Svgs } from "../../assets/images";
import { CareerGrid, PageWrapper, SubTitle, Title } from "./styles";

const Careers = ({history}) => {

  const handleClick = () => {
    history.push('/careers/1')
  }
  return (
    <PageWrapper>
      <Title>Open Positions</Title>
      <SubTitle>
        Different cultures. Different perspectives. Different Work styles. All
        part of Sabertooth Poker Club
      </SubTitle>
      <CareerGrid>
        <div className="grid-column">
          <div className="job-title-row">
            <p className="job-title">Talent Acquisition Specialist</p>
            <Svgs.ARROW_WHITE className="arrow" onClick={handleClick} />
          </div>
          <p className="location">Chicago/ Remote</p>
        </div>
        <div className="grid-column">
          <div className="job-title-row">
            <p className="job-title">Talent Acquisition Specialist</p>
            <Svgs.ARROW_WHITE className="arrow" onClick={handleClick} />
          </div>
          <p className="location">Chicago/ Remote</p>
        </div>
        <div className="grid-column">
          <div className="job-title-row">
            <p className="job-title">Talent Acquisition Specialist</p>
            <Svgs.ARROW_WHITE className="arrow" onClick={handleClick} />
          </div>
          <p className="location">Chicago/ Remote</p>
        </div>
        <div className="grid-column">
          <div className="job-title-row">
            <p className="job-title">Talent Acquisition Specialist</p>
            <Svgs.ARROW_WHITE className="arrow" onClick={handleClick} />
          </div>
          <p className="location">Chicago/ Remote</p>
        </div>
        <div className="grid-column">
          <div className="job-title-row">
            <p className="job-title">Talent Acquisition Specialist</p>
            <Svgs.ARROW_WHITE className="arrow" onClick={handleClick} />
          </div>
          <p className="location">Chicago/ Remote</p>
        </div>
        <div className="grid-column">
          <div className="job-title-row">
            <p className="job-title">Talent Acquisition Specialist</p>
            <Svgs.ARROW_WHITE className="arrow" onClick={handleClick} />
          </div>
          <p className="location">Chicago/ Remote</p>
        </div>
      </CareerGrid>
    </PageWrapper>
  );
};

export default Careers;
