import styled from "styled-components";

export const PageWrapper = styled.div`
  /* background: #000000; */

  .header-image {
    margin-top: 100px;
    object-fit: contain;
  }

  .scroll-pa{ 
    scroll-padding-top: 180px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0px 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    /* margin: 0px 150px; */
    padding: 10px 20px 0;

  }
`;

export const WelcomeText = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  font-size: 42px;
  line-height: 72px;
  color: #ffffff;
  text-transform: uppercase;
  /* width: 68%; */
  @media screen and (max-width: 1194px) {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    line-height: 48px;
    text-align: center;

    color: #ffffff;
  }
`;

export const SubText = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;

  color: #ffffff;
  /* width: 68%; */
  margin-top: 32px;
  @media screen and (max-width: 1194px) {
    text-align: center;
  }
`;

export const Button = styled.button`
  background: #d81159;
  border-radius: 8px;
  padding: 15px 120px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  outline: none;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 56px;
`;

export const RightContent = styled.div`
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const SaberImage = styled.img`
  width: 727px;
  object-fit: cover;
  @media screen and (max-width: 1194px) {
    width: 327px;
    object-fit: cover;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
`;

export const Mobile = styled.div`
  display: none;
  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MobileMD = styled.div`
  /* display: none; */
  position: relative;
  width: 100%;
  @media screen and (max-width: 737px) {
    display: none;
  }
`;

export const MobileSD = styled.div`
  display: none;
  @media screen and (max-width: 737px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
`;

export const Desktop = styled.div`
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  @media screen and (max-width: 1194px) {
    display: none;
  }
`;
