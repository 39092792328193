import styled from "styled-components";

export const PageWrapper = styled.div`
  background: #080808;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 197px 140px 197px;
  position: relative;

  @media screen and (max-width: 1194px) {
    display: flex;
    flex-direction: column;
    /* margin: 0px 150px; */
    padding: 140px 16px;
  }
`;

export const Title = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #cacaca;
  @media screen and (max-width: 1194px) {
    font-size: 14px;
    
  }
`;
export const JobTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  margin-top: 15px;
  color: #ffffff;
  @media screen and (max-width: 1194px) {
    font-size: 32px;
    margin-top: 4px;
  }
`;
export const CityTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #d81159;
  margin-top: 24px;
  @media screen and (max-width: 1194px) {
    font-size: 18px;
    margin-top: 8px;
  }
`;

export const AboutJobTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
  color: #ffffff;
  margin-top: 88px;
  @media screen and (max-width: 1194px) {
    font-size: 24px;
    margin-top: 56px;
  }
`;

export const AboutJob = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 24px;
  width: 80%;
  @media screen and (max-width: 1194px) {
    font-size: 14px;
    margin-bottom: 14px;
  }
`;

export const Paragraph = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 32px;
`;

export const SubTitle = styled.p`
  color: #ffffff;
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 120px;
  margin-top: 20px;
`;

export const ApplyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #d81159;
  border-radius: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  align-self: flex-start;
  border: none;
  padding: 20px 51px;
  margin-top: 94px;
  cursor: pointer;
  @media screen and (max-width: 1194px) {
    font-size: 14px;
    margin-bottom: 14px;
    padding: 13px 34px;
  }
`;
