import React, { forwardRef, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { PageWrapper, PokerWrapper, VideoWrapper } from "./styles";

import "swiper/swiper-bundle.css";
import { MobileMD, MobileSD } from "../../pages/Home/styles";
import { Pngs, Svgs } from "../../assets/images";
import { DiscordButton } from "../../pages/Poker/styles";

const About = forwardRef(({ ref }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const containerRef = useRef();
  const toggleSound = () => setIsMute(!isMute);
  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    const refCurrent = containerRef.current;
    const observer = new IntersectionObserver(callbackFunction, options);
    if (refCurrent) observer.observe(refCurrent);

    return () => {
      if (refCurrent) observer.unobserve(refCurrent);
    };
  }, [containerRef]);

  return (
    <PageWrapper className="about" ref={ref}>
      <div className="writeup-container">
        <p className="description">
          The Sabertooth represents a REBIRTH and the reclamation of POWER &
          SOVEREIGNTY.
        </p>
        <p className="description">
          With our NFTs and upcoming metaverse world called Chronos, Sabertooth
          Poker Club is uniquely positioned to lead this aesthetic REVOLUTION we
          haven't seen since the Renaissance. We are entering a new Earth that
          will unlock a level of ACCESS, OPPORTUNITY, and FINANCIAL EMPOWERMENT
          that is unprecedented in history.
        </p>
        <p className="description">
          Our vision at SPC is to unite ART with TECHNOLOGY to create a MOVEMENT
          that elevates CULTURE, builds COMMUNITY, and expands the COLLECTIVE
          CONSCIOUSNESS.
        </p>
      </div>

      <VideoWrapper ref={containerRef}>
        <ReactPlayer
          url={"https://d3a8mony9km4bv.cloudfront.net/promovi_poker_v01.mp4"}
          width="100%"
          height="auto"
          muted={isMute}
          controls={false}
          playing={isVisible}
          loop
        />
        <div className="mute-wrapper">
          {isMute ? (
            <Svgs.MUTE_WHITE onClick={toggleSound} className="mute" />
          ) : (
            <Svgs.UNMUTE_WHITE onClick={toggleSound} className="mute" />
          )}

          <p className="mute-text">Tap to {isMute ? "unmute" : "mute"}</p>
        </div>
      </VideoWrapper>
      <div className="writeup-wrapper">
        <div className="writeup-container-black">
          <p className="description white"> Our main goals:</p>
          <p className="description white">
            1) enable FINANCIAL EMPOWERMENT to the masses, and
          </p>
          <p className="description white">
            {" "}
            2) build an authentic, 100 year community.
          </p>
          <p className="description white">
            From both virtual and in-person poker, wellness and 
            ayahuasca retreats, we're building the most authentic 
            community as we level up as individuals.
          </p>
          <p className="description white">
            The metaverse is the next evolution of the Internet,
            and we believe our PLAY-TO-EARN POKER platform will play a critical
            role in onboarding millions of people.
          </p>
          <p className="description white">
            Our mission is to enable millions of people to
            MAKE MONEY IN THE METAVERSE starting with poker.
          </p>
        </div>
      </div>
      <MobileMD>
        <img className="avatars" src={Pngs.POKER_IMAGE} alt="" />

        <PokerWrapper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <p>
              Mint a Sabertooth NFT on April 15th to join our free POKER
              Metaverse!
            </p>
          */}
          <p>Play-To-Earn both active & PASSIVE INCOME!</p>
            <DiscordButton style={{ alignSelf: "center" }} href="/poker">
              Learn More
            </DiscordButton>
          </div>
        </PokerWrapper>
      </MobileMD>
      <MobileSD>
        <PokerWrapper>
          {/* <p>
            Mint a Sabertooth NFT on April 15th to join our free POKER
            Metaverse!
          </p>
        */}
        <p>Play-To-Earn both active & PASSIVE INCOME!</p>
          <DiscordButton href="/poker">Learn More</DiscordButton>
        </PokerWrapper>
        <img className="avatars" src={Pngs.POKER_IMAGE} alt="" />
      </MobileSD>
    </PageWrapper>
  );
});

export default About;
