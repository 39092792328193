import styled from "styled-components";

export const PageWrapper = styled.div`
  /* height: 100vh; */
  background: #080808;
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 60px;
`;

export const TitleWhite = styled.p`
  font-family: 'Cinzel Decorative', cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  margin-bottom: 55px;
  letter-spacing: 0.02em;
  /* line-height: 98px; */
  text-align: center;
  margin-top: 140px;
  color: #ffffff;
  @media screen and (max-width: 1194px) {
    font-size: 28px;
  }
`;

export const FAQrow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 60%;
  padding: 10px 0 10px;
  margin-bottom: 10px;
  transition: height ease 14s;
  border-bottom: 1px solid #726f6f;
  .faq-text {
    /* font-family: Poppins; */
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 16px;
    transition: height ease 14s;
    cursor: pointer;
  }
  .faq-content {
    /* font-family: Poppins; */
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #fdfdfd;
    width: 95%;
    transition: height ease 14s;
  }

  .icon {
    transition: height ease 14s;
  }
  @media screen and (max-width: 1194px) {
    width: 80%;
  }
`;
