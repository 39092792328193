import React from "react";
import { Pngs } from "../../assets/images";
import { MobileMD, MobileSD } from "../../pages/Home/styles";
import { PageWrapper } from "./styles";

const JumboHeader = () => {
  return (
    <PageWrapper>
      <p className="smc-club">Sabertooth Poker Club</p>
     
      <MobileMD>
        <p className="description">
        Sabertooth NFTs are your membership to our 
        </p>
      <p className="description">
      3D fully immersive metaverse with a sustainable,
      </p>
      <p className="description">
      Play-to-Earn economy for all NFT communities.
      </p>
        <img className="avatars" src={Pngs.JUMBO_PIC} alt="" />
      </MobileMD>
      <MobileSD>
      <p className="description">
      Sabertooth NFTs are your membership to our
      </p>
      <p className="description">3D fully immersive metaverse with a sustainable, </p>
        <p className="description">
        Play-to-Earn economy for all NFT communities.
        </p>
        <img className="avatars" src={Pngs.MD_JUMBO_PIC} alt="" />
      </MobileSD>
    </PageWrapper>
  );
};

export default JumboHeader;
