import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  /* padding: 50px 194px 50px; */

  /* height: 100vh; */
  /* position: relative; */
  .avatars {
    /* position: absolute; */
    /* height: 537px; */
    width: 100%;
    height: 899px;
    bottom: 0;
    object-fit: cover;
    object-position: center;
    margin-bottom: -5px;
  }
  .about-title {
    font-family: "Cinzel Decorative", cursive;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #1e1e1e;
    margin-bottom: 32px;
  }
  [type="radio"] {
    display: none;
  }
  .writeup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding: 50px 194px 50px;
    width: 40%;
  }

  .writeup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000000;
    width: 100%;
    /* padding: 50px 194px 50px; */
  }
  .writeup-container-black {
    background: #000000;
    padding: 50px 194px 50px;
    width: 30%;
  }
  .white {
    color: #ffffff !important;
  }
  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;

    text-align: center;

    color: #1e1e1e;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .carousel-wrapper {
    width: 100%;
    height: 300px;
    margin: 0 auto;
  }

  .image {
    width: 524px;
    height: 548px;
    object-fit: cover;
    /* height: 300px; */
  }

  .slide img {
    width: 25rem;
    margin: 0 auto;
  }

  .slide {
    transform: scale(0.8);
    transition: transform 300ms;
    /* opacity: 0.5; */
  }

  .activeSlide {
    transform: scale(1.1);
    opacity: 1;
    position: relative;
    z-index: 1;
    margin: 0 auto;
  }

  .inactiveSlide {
    /* transform: scale(1.1); */
    /* opacity: 0.5; */
    /* position: absolute;
    left: 0;
    right: 0;
  top: 30px; */
    z-index: -1;
  }
  @media screen and (max-width: 1194px) {
    /* padding: 42px 16px 0; */
    .avatars {
      /* position: absolute; */
      /* height: 537px; */
      width: 100%;
      height: unset;
      bottom: 0;
      object-fit: cover;
      margin-bottom: -5px;
    }
    .about-title {
      font-family: "Cinzel Decorative", cursive;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 31px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #1e1e1e;
      margin-bottom: 16px;
    }
    .description {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      text-align: center;
      color: #1e1e1e;
      margin-top: 0px;
    }

    .carousel-wrapper {
      width: 80%;
      height: 200px;
      margin: 0 auto;
    }
    .writeup-container {
      padding: 22px 16px 0;
      width: 90%;
    }

    .writeup-container-black {
      padding: 22px 16px 0;
      width: 90%;
    }
    .writeup-wrapper {
      /* width: 90%; */
    }
  }
`;

export const Section = styled.section`
  position: relative;
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #2196f3; */
  overflow: hidden;
  margin-bottom: 30px;
  /* background: linear-gradient(90deg, #000000 -59.09%, rgba(185, 185, 185, 0) 224.09%); */
  .swiper {
    width: 100%;
    /* padding-top: 50px; */
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 454px;
    /* height: 548px; */
    /* height: 548px; */

    /* height: 200px; */
    margin-right: 0;
    margin-left: 0;
    @media screen and (max-width: 1194px) {
      width: 250px;
    }
  }
  .swiper-slide-active {
    margin-right: 0 !important;
    margin-left: -50px !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    bottom: 0 !important;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 3px;
    display: inline-block;
    border-radius: 0;
    /* background:  #000;
    opacity: 0.2; */
  }
  .swiper-pagination-bullet-active {
    background: #1e1e1e;
  }
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .swiper-3d .swiper-slide-shadow-left,
  .swiper-3d .swiper-slide-shadow-right {
    background-image: none;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;

  position: relative;
  margin-bottom: -5px;
  .mute-wrapper {
    position: absolute;
    bottom: 20px;
    right: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mute {
    cursor: pointer;
  }
  .mute-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;

    text-align: center;

    color: #ffffff;
  }
  @media screen and (max-width: 1194px) {
    .mute-wrapper {
      bottom: 10px;
      right: 10px;
    }
  }
`;

export const PokerWrapper = styled.div`
  position: absolute;
  top: 350px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  p {
    /* color: white; */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1194px) {
    top: 50px;
    p {

    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
  }
  }
`;
