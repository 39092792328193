import Home from "../pages/Home";
import Terms from "../pages/Terms";
// import RoadMap from "../pages/RoadMap";
import Careers from "../pages/Careers";
import CareersDetail from "../pages/CareersDetail";
import Poker from "../pages/Poker";




export const publicRoutes = [
  {
    url: '/',
    component: Home,
    exact: true,
  },
  {
    url: '/terms',
    component: Terms,
    exact: true,
  },
  // {
  //   url: '/roadmap',
  //   component: RoadMap,
  //   exact: true,
  // },
  {
    url: '/careers',
    component: Careers,
    exact: true,
  },
  {
    url: '/careers/:id',
    component: CareersDetail,
    exact: true,
  },
  {
    url: '/poker',
    component: Poker,
    exact: true,
  },
];
