import React, { forwardRef, useEffect, useState } from "react";
import { Link as LogoLink } from "react-router-dom";
// import { Link } from "react-router-dom";
import { Svgs } from "../../assets/images";
import {
  IconsWrapper,
  MenuIconWrapper,
  MobileNav,
  MPageTitle,
  NavWrapper,
  PageTitle,
  Mobile,
  DiscordButton,
} from "./styles";

const Nav = forwardRef(
  ({
    aboutRef,
    aboutMRef,
    roadMapRef,
    roadMapMobileRef,
    teamRef,
    faqRef,
    teamMobileRef,
    faqMobileRef,
  }) => {
    const [open, setOpen] = useState(false);
    const [colorChange, setColorchange] = useState(false);

    const changeNavbarColor = () => {
      if (window.scrollY >= 80) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    };

    useEffect(() => {
      window.addEventListener("scroll", changeNavbarColor);

      return () => {
        window.removeEventListener("scroll", changeNavbarColor);
      };
    }, []);

    const handleNav = () => {
      window.open("https://discord.gg/sabertoothpokerclub", "_blank");
    };

    return (
      <NavWrapper colorChange={colorChange} open={open}>
        <div className="nav">
          {open ? (
            <MenuIconWrapper>
              <Svgs.CLOSE onClick={() => setOpen(false)} />
            </MenuIconWrapper>
          ) : (
            <MenuIconWrapper>
              <Svgs.MENU onClick={() => setOpen(true)} />
            </MenuIconWrapper>
          )}
          <div className="logo-section">
            <LogoLink to="/">
              <Svgs.LOGO width={90} height={65} className="icon" />
            </LogoLink>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="saber-tooth">Sabertooth </p>
              <p className="mclub">Motor Club</p>
            </div>
          </div>
          <div className="menu">
            {/* <Link
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            exact="true"
            activeClass="active-link"
          > */}
            <PageTitle
              onClick={() =>
                aboutRef.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              Intro
            </PageTitle>
            {/* </Link> */}
            {/* <Link
            to="roadmap"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            activeClass="active-link"
          > */}
            <PageTitle
              onClick={() =>
                roadMapRef.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              Roadmap
            </PageTitle>
            {/* </Link> */}

            <PageTitle
              onClick={() =>
                teamRef.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              Team
            </PageTitle>

            <PageTitle
              onClick={() =>
                faqRef.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              FAQ
            </PageTitle>
            {/* <Link to="/poker">Poker</Link> */}
          </div>
          <IconsWrapper>
            <a
              href="https://www.instagram.com/sabertoothpokerclub"
              target="_blank"
              rel="noreferrer"
            >
              <Svgs.INSTAGRAM />
            </a>

            <a
              href="https://www.twitter.com/sabertoothpoker"
              target="_blank"
              rel="noreferrer"
            >
              <Svgs.TWITTER />
            </a>
            {!open && (
              <DiscordButton onClick={handleNav}>
                <span>Join our Discord </span>
                <Svgs.DISCORD />
              </DiscordButton>
            )}
          </IconsWrapper>
        </div>
        <Mobile>
          {open && (
            <MobileNav>
              {/* <Link
              to="info"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              activeClass="active-link"
            > */}
              <MPageTitle
                onClick={() => {
                  aboutMRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                  setOpen(false);
                }}
              >
                Info
              </MPageTitle>
              {/* </Link> */}

              <MPageTitle
                onClick={() => {
                  roadMapMobileRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                  setOpen(false);
                }}
              >
                Roadmap
              </MPageTitle>

              <MPageTitle
                onClick={() => {
                  teamMobileRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                  setOpen(false);
                }}
              >
                Team
              </MPageTitle>

              <MPageTitle
                onClick={() => {
                  faqMobileRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                  setOpen(false);
                }}
              >
                FAQ
              </MPageTitle>
              {/* <Link
                style={{display: "flex", alignItems: "center" }}
                className="mb-menu-link"
                to="/poker"
              >
                Poker
              </Link> */}
            </MobileNav>
          )}
        </Mobile>
      </NavWrapper>
    );
  }
);

export default Nav;
