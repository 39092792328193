import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {  publicRoutes } from './routes';

const Routes = () => (
    <Switch>
      {publicRoutes.map((route, index) => {
        return <Route path={route.url} component={route.component} key={index} exact />;
      })}
    </Switch>
);

export default Routes;
